// src/components/DynamicPage.js
import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

function DynamicPage() {
    const [pageItems, setPageItems] = useState([]);

    useEffect(() => {
        // Fetch danych na starcie komponentu
        fetch("https://akpkrab.pl:443/pageItems", {
            method: 'GET',
            headers: {
                'Authorization': 'a9b844871969b44bb5bb1845ca0e6b68669d544cab184422e76265e6f06b8068'
            }
        })
            .then(res => res.json())
            .then(
                (result) => {
                    setPageItems(result); // Ustawienie sliderItems po fetchu
                }
            );
    }, []);

    const pageId = useLocation().pathname;
    const page = pageItems.find(p => ("/" + p.link.toString()) === pageId);

    if (!page) {
        return (
            <div className="subpage-container">
                <h1 className="subpage-title">Strony nie znaleziono</h1>
                <div className="subpage-content">
                    <img src="https://via.placeholder.com/1920x1080" alt="Brak" />
                    <p>Strony nie znaleziono</p>
                    <a href="https://www.akpkrab.pl" className="button-back">Powrót do strony głównej</a>
                </div>
            </div>
        )
    }
    if (page.type === "active") {
        return (
            <div className="subpage-container">
                <h1 className="subpage-title">{page.title}</h1>
                <div className="subpage-content">
                    <img src={"https://www.akpkrab.pl/uploads/" + page.image} alt={page.title} />
                    <p>{page.text}</p>
                </div>
            </div>
        );
    }
}

export default DynamicPage;
