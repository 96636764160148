// src/components/SlideDetail.js

import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import './SubPage.css';

const SlideDetail = () => {
    const [sliderItems, setSliderItems] = useState([]);

    useEffect(() => {
        // Fetch danych na starcie komponentu
        fetch("https://akpkrab.pl:443/sliderItems", {
            method: 'GET',
            headers: {
                'Authorization': 'a9b844871969b44bb5bb1845ca0e6b68669d544cab184422e76265e6f06b8068'
            }
        })
            .then(res => res.json())
            .then(
                (result) => {
                    setSliderItems(result); // Ustawienie sliderItems po fetchu
                }
            );
    }, []);


    const { id } = useParams(); // Pobranie ID z URL
    const slide = sliderItems[id]; // Pobranie odpowiedniego slajdu na podstawie ID

    if (!slide) {
        return (
            <div className="subpage-container">
                <h1 className="subpage-title">Slidera nie znaleziono</h1>
                <div className="subpage-content">
                    <img src="https://via.placeholder.com/1920x1080" alt="Brak" />
                    <p>Slidera nie znaleziono</p>
                    <a href="https://www.akpkrab.pl" className="button-back">Powrót do strony głównej</a>
                </div>
            </div>
        )
    }
    if (slide.type === "active") {
        return (
            <div className="subpage-container">
                <div className="subpage-content">
                    <div className="image-container">
                        <img src={"https://www.akpkrab.pl/uploads/" + slide.image} alt={slide.title} className="subpage-image" />
                        <div className="image-overlay">
                            <h1 className="overlay-title">{slide.title}</h1>
                        </div>
                    </div>
                    <p className="subpage-text">{slide.text}</p>
                    <a href="https://www.akpkrab.pl" className="button-back">Powrót do strony głównej</a>
                </div>
            </div>
        );
    }
};

export default SlideDetail;
