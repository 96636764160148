import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import './Footer.css'; // Plik CSS dla stylów stopki

const Footer = () => {

    const [pagesData, setPageItems] = useState([]);

    useEffect(() => {
        // Fetch danych na starcie komponentu
        fetch("https://akpkrab.pl:443/pageItems", {
            method: 'GET',
            headers: {
                'Authorization': 'a9b844871969b44bb5bb1845ca0e6b68669d544cab184422e76265e6f06b8068'
            }
        })
            .then(res => res.json())
            .then(
                (result) => {
                    setPageItems(result); // Ustawienie sliderItems po fetchu
                }
            );
    }, []);

    return (
        <footer className="footer">
            <div className="footer-content">
                <div className="footer-column">
                    <ul className="footer-links">
                        {pagesData.map((page) => {
                            if (page.type === "active") {
                                return (
                                    <li key={page.id}>
                                        <Link to={"/" + page.link}>{page.title}</Link>
                                    </li>
                                )
                            }
                        })}
                        <li>
                            <a href={`/rezerwacja`}>Rezerwacja sprzętu</a>
                        </li>
                    </ul>
                </div>
                <div className="footer-column">
                    <div className="footer-address">
                        <p>Władysława Reymonta 21A, 30-059 Kraków</p>
                    </div>
                </div>
                <div className="footer-column">
                    <div className="footer-map">
                        <iframe
                            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2562.2997855939834!2d19.906573676548714!3d50.06578921705652!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47165b08046e3e19%3A0x8de20f0e86e861f8!2sW%C5%82adys%C5%82awa%20Reymonta%2021A%2C%2030-059%20Krak%C3%B3w!5e0!3m2!1spl!2spl!4v1695114519471!5m2!1spl!2spl"
                            width="600"
                            height="450"
                            style={{ border: 0 }}
                            allowFullScreen=""
                            loading="lazy"
                            title="Map to location"
                        ></iframe>
                    </div>
                </div>
            </div>
            <div className="footer-copyright">
                <p>&copy; {new Date().getFullYear()} AKP KRAB. Wszelkie prawa zastrzeżone.</p>
            </div>
        </footer>
    );
};

export default Footer;