// src/index.js
import React from 'react';
import ReactDOM from 'react-dom/client'; // Poprawny import createRoot
import App from './App';
import './index.css'; // Jeśli masz plik CSS

// Utwórz root
const root = ReactDOM.createRoot(document.getElementById('root'));

// Renderuj aplikację
root.render(
    <React.StrictMode>
        <App />
    </React.StrictMode>
);
