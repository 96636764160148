// src/App.js

import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import SliderComponent from './components/SliderComponent';
import NewsList from './components/NewsList';
import NewsDetail from './components/NewsDetail';
import SliderDetail from './components/SliderDetail';
import StickyMenu from './components/StickyMenu';
import Footer from './components/Footer';
import DynamicPage from './components/DynamicPage';

import WeWhoWeAre from './components/pages/WeWhoWeAre';
import WeHistory from './components/pages/WeHistory';

import './App.css';

function App() {
    const [pagesData, setPageItems] = useState([]);

    useEffect(() => {
        // Fetch danych na starcie komponentu
        fetch("https://akpkrab.pl:443/pageItems", {
            method: 'GET',
            headers: {
                'Authorization': 'a9b844871969b44bb5bb1845ca0e6b68669d544cab184422e76265e6f06b8068'
            }
        })
            .then(res => res.json())
            .then(
                (result) => {
                    setPageItems(result); // Ustawienie sliderItems po fetchu
                }
            );
    }, []);

    return (
        <Router>
            <div className="app">
                <StickyMenu />
                <Routes>
                    <Route path="/" element={<><SliderComponent /><NewsList /></>} />
                    {pagesData.map((page) => (
                        <Route key={page.id} path={"/" + page.link} element={<DynamicPage />} />
                    ))}
                    <Route path="/o-nas/kim-jestesmy" element={<WeWhoWeAre />} />
                    <Route path="/o-nas/historia-kraba" element={<WeHistory />} />
                    <Route path="/news/:id" element={<NewsDetail />} />
                    <Route path="/slider/:id" element={<SliderDetail />} />
                    {/* <Route path="/*" element={<Navigate to="/" replace />} /> */}
                </Routes>
                <Footer />
            </div>
        </Router>
    );
}

export default App;
